/**
 * 加盟店側-案内板（各種申請）画面
 * メモ：変更・解約に関する案内を表示する
 */
import React, { useCallback } from "react";

import { Link } from "react-router-dom";

import clsx from "clsx";
import { Container, makeStyles, Box, Grid, Button } from "@material-ui/core";

import { useContainerStyles } from "../../../../templates/styles";
import { buttonStyles } from "../../../../common/components/styles";

import { GUEST_COLOR, FONT } from "../../../../../constants/common";
import {
  checkAuth,
  isSysAd,
  getUserContractorId,
} from "../../../../../utils/auth";

import Dashboard from "../../../../templates/Dashboard";

const useStyles = makeStyles(() => ({
  text: {
    marginTop: 10,
    fontFamily: FONT,
  },
  box: {
    width: 600,
  },
  buttonBox: {
    display: "inline",
  },
  guidanceBox: {
    color: "red",
    border: "1px solid red",
    borderRadius: 4,
    padding: "2px 10px",
    display: "inline-block",
  },
  itemText: {
    color: "#263962",
  },
  contentText: {
    color: "#000000",
  },
  contentButton: {
    color: "#000000",
  },
  remarksText: {
    fontSize: 10,
    marginTop: "-15px",
  },
  explanationBox: {
    paddingLeft: 16,
    fontWeight: "bold",
    fontSize: 12,
  },
  notesText: {
    color: "#263962",
    fontSize: 12,
  },
  linkButton: {
    textAlign: "center",
    lineHeight: 1,
    padding: "6px 15px",
    display: "inline-block",
  },
  gridContainer: {
    marginLeft: 16,
    padding: "10px 3px 10px 3px",
    alignItems: "center",
  },
}));

const GuestGuidePage: React.FC = () => {
  const containerClasses = useContainerStyles();
  const classes = useStyles();
  const buttonClasses = buttonStyles({
    width: 140,
    marginTop: 0,
    backgroundColor: GUEST_COLOR.primary,
  });

  // 各リンクボタンの作成
  const GuidButton = useCallback(
    ({ name, linkType }) => {
      let disabled = true;
      let url = "";
      // リンク先の作成と権限チェック
      if (linkType === "contractor") {
        // 契約者変更（システム担当ユーザーは契約者検索画面,それ以外は契約者詳細画面へ）
        url = isSysAd()
          ? "/guest/contractor"
          : `/guest/contractor/${getUserContractorId()}`;
        // 契約者編集権限がなければ変更申請もできない
        disabled = !checkAuth("contractorEdit");
      } else if (linkType === "customer") {
        // 加盟店変更、加盟店解約
        url = "/guest/customer";
        disabled = !checkAuth("changeDealsCreate");
      } else if (linkType === "terminal") {
        // 端末変更、端末解約
        url = "/guest/terminal";
        disabled = !checkAuth("changeDealsCreate");
      } else if (linkType === "addTerminal") {
        // 端末増設 (権限チェックが違うため切り出し)
        url = "/guest/customer";
        // 新規申請の権限があるか
        disabled = !checkAuth("newDealsCreate");
      }

      return (
        <Box className={classes.buttonBox}>
          <Button
            className={clsx(buttonClasses.button, classes.linkButton)}
            type="button"
            variant="contained"
            component={Link}
            to={url}
            disabled={disabled}
          >
            {name}
          </Button>
        </Box>
      );
    },
    [buttonClasses.button, classes.linkButton, classes.buttonBox]
  );

  return (
    <Dashboard
      title="各種申請"
      color={GUEST_COLOR.base}
      backgroundColor={GUEST_COLOR.primary}
      iconName="guide"
    >
      <Container maxWidth="lg" className={containerClasses.container}>
        {/* 先方指定の文言 */}
        <div className={classes.text}>
          <h2>契約やご利用施設・店舗の情報に変更がある場合</h2>
          <br />
          加盟店（施設･店舗）の情報や、経営情報、連絡先など、ご契約内容に変更が発生した場合は、
          <br />
          下記注意事項をご確認の上で、お手続き、またはご連絡をお願いいたします。
          <br />
          <br />
          <b>＜申請の進め方＞</b>
          <br />
          <Box className={classes.explanationBox}>
            ①以下にあるボタンを押して各管理画面へ遷移してください。
            <br />
            ②各管理画面で検索を行っていただき、変更対象の加盟店、もしくは端末の詳細ボタンをクリックしてください。
            <br />
            ③表示された画面の上部に申請用のボタンがございますので、そちらをクリックし申請処理に進んでください。
          </Box>
          <br />
          <Box className={classes.box}>
            {/* 端末を増やしたい／減らしたい */}
            <h3>端末を増やしたい／減らしたい</h3>
            <Grid container spacing={0} className={classes.gridContainer}>
              <Grid item className={classes.itemText} xs={5}>
                端末を増やす
              </Grid>
              <Grid item className={classes.contentText} xs={2}>
                加盟店管理
              </Grid>
              <Grid item className={classes.contentText} xs={4}>
                ⇒&nbsp;
                <GuidButton name="端末増設申請" linkType="addTerminal" />
              </Grid>
            </Grid>
            <Grid container spacing={0} className={classes.gridContainer}>
              <Grid item className={classes.itemText} xs={5}>
                端末を減らす
              </Grid>
              <Grid item className={classes.contentText} xs={2}>
                端末管理
              </Grid>
              <Grid item className={classes.contentText} xs={4}>
                ⇒&nbsp;
                <GuidButton name="端末解約申請" linkType="terminal" />
              </Grid>
            </Grid>
            {/* 新規施設・店舗をオープンするので端末を設置したい */}
            <h3>新規施設・店舗をオープンするので端末を設置したい</h3>
            <Grid container spacing={0} className={classes.gridContainer}>
              <Grid item className={classes.itemText} xs={2} />
              <Grid item className={classes.contentText}>
                ⇒&nbsp;
                <Box className={classes.guidanceBox}>
                  JTB一括加盟店決済サービスセンターまでご連絡ください
                </Box>
              </Grid>
            </Grid>
            {/* POSと連動／解除させたい */}
            <Grid container spacing={0} className={classes.gridContainer}>
              <Grid item className={classes.contentText} xs={5}>
                <h3 style={{ marginLeft: "-19px" }}>POSと連動／解除させたい</h3>
              </Grid>
              <Grid item className={classes.contentText} xs={2}>
                端末管理
              </Grid>
              <Grid item className={classes.contentText} xs={4}>
                ⇒&nbsp;
                <GuidButton name="端末変更申請" linkType="terminal" />
              </Grid>
            </Grid>
            {/* 加盟店（施設・店舗）の情報を変更したい */}
            <h3>加盟店（施設・店舗）の情報を変更したい</h3>
            <Grid container spacing={0} className={classes.gridContainer}>
              <Grid item className={classes.itemText} xs={5}>
                名称の変更
              </Grid>
              <Grid item className={classes.contentText} xs={2}>
                加盟店管理
              </Grid>
              <Grid item className={classes.contentText} xs={4}>
                ⇒&nbsp;
                <GuidButton name="加盟店変更申請" linkType="customer" />
              </Grid>
            </Grid>
            <Grid container spacing={0} className={classes.gridContainer}>
              <Grid item className={classes.itemText} xs={5} />
              <Grid item className={classes.remarksText}>
                ※ご利用者の明細に表示される名称が変わります
              </Grid>
            </Grid>
            <Grid container spacing={0} className={classes.gridContainer}>
              <Grid item className={classes.itemText} xs={5} />
              <Grid item className={classes.contentText} xs={2}>
                端末管理
              </Grid>
              <Grid item className={classes.contentText} xs={4}>
                ⇒&nbsp;
                <GuidButton name="端末変更申請" linkType="terminal" />
              </Grid>
            </Grid>
            <Grid container spacing={0} className={classes.gridContainer}>
              <Grid item className={classes.itemText} xs={5} />
              <Grid item className={classes.remarksText}>
                ※端末から印字される売上票の名称が変わります
              </Grid>
            </Grid>
            <Grid container spacing={0} className={classes.gridContainer}>
              <Grid item className={classes.itemText} xs={1} />
              <Grid item className={classes.itemText}>
                〔端末からの印字のみを変更〕
              </Grid>
            </Grid>
            <Grid container spacing={0} className={classes.gridContainer}>
              <Grid item className={classes.itemText} xs={5} />
              <Grid item className={classes.contentText} xs={2}>
                端末管理
              </Grid>
              <Grid item className={classes.contentText} xs={4}>
                ⇒&nbsp;
                <GuidButton name="端末変更申請" linkType="terminal" />
              </Grid>
            </Grid>
            <Grid container spacing={0} className={classes.gridContainer}>
              <Grid item className={classes.itemText} xs={5}>
                住所を変更
              </Grid>
              <Grid item className={classes.contentText} xs={2}>
                加盟店管理
              </Grid>
              <Grid item className={classes.contentText} xs={4}>
                ⇒&nbsp;
                <GuidButton name="加盟店変更申請" linkType="customer" />
              </Grid>
            </Grid>
            <Grid container spacing={0} className={classes.gridContainer}>
              <Grid item className={classes.itemText} xs={5}>
                電話番号を変更したい
              </Grid>
              <Grid item className={classes.contentText} xs={2}>
                加盟店管理
              </Grid>
              <Grid item className={classes.contentText} xs={4}>
                ⇒&nbsp;
                <GuidButton name="加盟店変更申請" linkType="customer" />
              </Grid>
            </Grid>
            <Grid container spacing={0} className={classes.gridContainer}>
              <Grid item className={classes.itemText} xs={5} />
              <Grid item className={classes.remarksText}>
                ※JTBからの連絡先電話番号が変わります
              </Grid>
            </Grid>
            <Grid container spacing={0} className={classes.gridContainer}>
              <Grid item className={classes.itemText} xs={5} />
              <Grid item className={classes.contentText} xs={2}>
                端末管理
              </Grid>
              <Grid item className={classes.contentText} xs={4}>
                ⇒&nbsp;
                <GuidButton name="端末変更申請" linkType="terminal" />
              </Grid>
            </Grid>
            <Grid container spacing={0} className={classes.gridContainer}>
              <Grid item className={classes.itemText} xs={5} />
              <Grid item className={classes.remarksText}>
                ※端末から印字される売上票の電話番号が変わります
              </Grid>
            </Grid>
            {/* 契約に関する情報を変更したい */}
            <h3>契約に関する情報を変更したい</h3>
            <Grid container spacing={0} className={classes.gridContainer}>
              <Grid item className={classes.itemText}>
                同一法人（法人番号の変更がない）登記情報の変更の場合は、
                <br />
                履歴事項全部証明書が必要です。
              </Grid>
            </Grid>
            <Grid container spacing={0} className={classes.gridContainer}>
              <Grid item className={classes.itemText} xs={5}>
                社名を変更
              </Grid>
              <Grid item className={classes.contentText} xs={2}>
                契約者管理
              </Grid>
              <Grid item className={classes.contentText} xs={4}>
                ⇒&nbsp;
                <GuidButton name="契約者変更申請" linkType="contractor" />
              </Grid>
            </Grid>
            <Grid container spacing={0} className={classes.gridContainer}>
              <Grid item className={classes.itemText} xs={5} />
              <Grid item className={classes.remarksText}>
                ※証明書類（PDF）の添付が必要です
              </Grid>
            </Grid>
            <Grid container spacing={0} className={classes.gridContainer}>
              <Grid item className={classes.itemText} xs={5} />
              <Grid item className={classes.contentText} xs={2}>
                加盟店管理
              </Grid>
              <Grid item className={classes.contentText} xs={4}>
                ⇒&nbsp;
                <GuidButton name="加盟店変更申請" linkType="customer" />
              </Grid>
            </Grid>
            <Grid container spacing={0} className={classes.gridContainer}>
              <Grid item className={classes.itemText} xs={5} />
              <Grid item className={classes.remarksText}>
                ※証明書類（PDF）の添付が必要です
              </Grid>
            </Grid>
            <Grid container spacing={0} className={classes.gridContainer}>
              <Grid item className={classes.itemText} xs={5}>
                代表者を変更
              </Grid>
              <Grid item className={classes.contentText} xs={2}>
                契約者管理
              </Grid>
              <Grid item className={classes.contentText} xs={4}>
                ⇒&nbsp;
                <GuidButton name="契約者変更申請" linkType="contractor" />
              </Grid>
            </Grid>
            <Grid container spacing={0} className={classes.gridContainer}>
              <Grid item className={classes.itemText} xs={5} />
              <Grid item className={classes.remarksText}>
                ※証明書類（PDF）の添付が必要です
              </Grid>
            </Grid>
            <Grid container spacing={0} className={classes.gridContainer}>
              <Grid item className={classes.itemText} xs={5}>
                住所・電話番号を変更
              </Grid>
              <Grid item className={classes.contentText} xs={2}>
                契約者管理
              </Grid>
              <Grid item className={classes.contentText} xs={4}>
                ⇒&nbsp;
                <GuidButton name="契約者変更申請" linkType="contractor" />
              </Grid>
            </Grid>
            <Grid container spacing={0} className={classes.gridContainer}>
              <Grid item className={classes.itemText} xs={5} />
              <Grid item className={classes.remarksText}>
                ※証明書類（PDF）の添付が必要です
              </Grid>
            </Grid>
            <Grid container spacing={0} className={classes.gridContainer}>
              <Grid item className={classes.itemText} xs={5}>
                連絡先・担当者を変更
              </Grid>
              <Grid item className={classes.contentText} xs={2}>
                契約者管理
              </Grid>
              <Grid item className={classes.contentText} xs={4}>
                ⇒&nbsp;
                <GuidButton name="契約者変更申請" linkType="contractor" />
              </Grid>
            </Grid>
            <Grid container spacing={0} className={classes.gridContainer}>
              <Grid item className={classes.itemText} xs={5} />
              <Grid item className={classes.remarksText}>
                ※契約者様のご連絡先・ご担当者様を変更します
              </Grid>
            </Grid>
            <Grid container spacing={0} className={classes.gridContainer}>
              <Grid item className={classes.itemText} xs={5} />
              <Grid item className={classes.contentText} xs={2}>
                加盟店管理
              </Grid>
              <Grid item className={classes.contentText} xs={4}>
                ⇒&nbsp;
                <GuidButton name="加盟店変更申請" linkType="customer" />
              </Grid>
            </Grid>
            <Grid container spacing={0} className={classes.gridContainer}>
              <Grid item className={classes.itemText} xs={5} />
              <Grid item className={classes.remarksText}>
                ※加盟店様のご連絡先・ご担当者様を変更します
              </Grid>
            </Grid>
            <Grid container spacing={0} className={classes.gridContainer}>
              <Grid item className={classes.itemText}>
                銀行口座情報を変更（JTB協定宿泊施設を除く）
              </Grid>
            </Grid>
            <Grid container spacing={0} className={classes.gridContainer}>
              <Grid item className={classes.itemText} xs={5} />
              <Grid item className={classes.contentText} xs={2}>
                加盟店管理
              </Grid>
              <Grid item className={classes.contentText} xs={4}>
                ⇒&nbsp;
                <GuidButton name="加盟店変更申請" linkType="customer" />
              </Grid>
            </Grid>
            <Grid container spacing={0} className={classes.gridContainer}>
              <Grid item className={classes.itemText} xs={5} />
              <Grid item className={classes.remarksText}>
                ※証明書類（PDF）の添付が必要です
              </Grid>
            </Grid>
            <Grid container spacing={0} className={classes.gridContainer}>
              <Grid item className={classes.itemText} xs={2} />
              <Grid item className={classes.contentText}>
                銀行口座情報にあわせて法人情報に変更がある場合は
              </Grid>
            </Grid>
            <Grid container spacing={0} className={classes.gridContainer}>
              <Grid item className={classes.itemText} xs={2} />
              <Grid item className={classes.contentText}>
                ⇒&nbsp;
                <Box className={classes.guidanceBox}>
                  JTB一括加盟店決済サービスセンターまでご連絡ください
                </Box>
              </Grid>
            </Grid>
            <Grid container spacing={0} className={classes.gridContainer}>
              <Grid item className={classes.itemText}>
                経営形態や法人格の変更の場合
              </Grid>
            </Grid>
            <Grid container spacing={0} className={classes.gridContainer}>
              <Grid item className={classes.itemText} xs={6}>
                法人の種類・会社の種類の変更
                <br />
                個人事業から法人への変更
              </Grid>
              <Grid item className={classes.contentText} xs={5}>
                <Box className={classes.guidanceBox}>
                  JTB一括加盟店決済サービス
                  <br />
                  センターまでご連絡ください
                </Box>
              </Grid>
            </Grid>
            <Grid container spacing={0} className={classes.gridContainer}>
              <Grid item className={classes.itemText}>
                経営する法人の変更の場合
              </Grid>
            </Grid>
            <Grid container spacing={0} className={classes.gridContainer}>
              <Grid item className={classes.itemText} xs={6}>
                会社合併や分割による変更
                <br />
                事業譲渡（営業譲渡）による変更
              </Grid>
              <Grid item className={classes.contentText} xs={5}>
                <Box className={classes.guidanceBox}>
                  JTB一括加盟店決済サービス
                  <br />
                  センターまでご連絡ください
                </Box>
              </Grid>
            </Grid>
            <Grid container spacing={0} className={classes.gridContainer}>
              <Grid item className={classes.itemText} xs={6}>
                個人事業における事業継承の場合
              </Grid>
              <Grid item className={classes.contentText} xs={5}>
                <Box className={classes.guidanceBox}>
                  JTB一括加盟店決済サービス
                  <br />
                  センターまでご連絡ください
                </Box>
              </Grid>
            </Grid>
            {/* 解約を希望する場合 */}
            <h3>解約を希望する場合</h3>
            <Grid container spacing={0} className={classes.gridContainer}>
              <Grid item className={classes.itemText}>
                サービス利用・加盟店契約の解約
              </Grid>
            </Grid>
            <Grid container spacing={0} className={classes.gridContainer}>
              <Grid item className={classes.itemText} xs={2} />
              <Grid item className={classes.contentText}>
                ⇒&nbsp;
                <Box className={classes.guidanceBox}>
                  JTB一括加盟店決済サービスセンターまでご連絡ください
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Grid container spacing={0} className={classes.gridContainer}>
            <Grid item className={classes.notesText}>
              情報変更のお手続きにあたり、予めご承知ください。
              <br />
              ※申請・お手続きには、審査が必要な場合があり、審査完了後の反映となるためお時間をいただく場合があります。
              <br />
              ※変更の内容によっては、あらためてのお申込、または追加書類をご提出いただく場合がございます。
            </Grid>
          </Grid>
        </div>
      </Container>
    </Dashboard>
  );
};

export default GuestGuidePage;
